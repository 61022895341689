<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.3859 1.81038C19.3859 1.81038 19.3859 1.81898 19.3859 1.82327L14.3844 18.3181C14.3087 18.586 14.1528 18.8244 13.9378 19.0012C13.7227 19.178 13.4587 19.2848 13.1812 19.3073C13.1417 19.3107 13.1022 19.3124 13.0626 19.3124C12.8026 19.3132 12.5477 19.2396 12.3282 19.1003C12.1086 18.961 11.9334 18.7617 11.8234 18.5261L8.6953 12.1066C8.664 12.0422 8.65355 11.9697 8.66542 11.8992C8.67728 11.8286 8.71086 11.7635 8.76147 11.713L13.739 6.73546C13.8625 6.60548 13.9303 6.4324 13.928 6.25313C13.9257 6.07385 13.8535 5.90257 13.7267 5.77579C13.5999 5.64902 13.4286 5.57678 13.2494 5.57449C13.0701 5.57219 12.897 5.64002 12.767 5.76351L7.78694 10.741C7.73638 10.7916 7.67129 10.8252 7.60073 10.8371C7.53018 10.8489 7.45768 10.8385 7.39335 10.8072L0.967803 7.67991C0.716539 7.55937 0.507835 7.36532 0.369349 7.12348C0.230863 6.88164 0.169132 6.60343 0.192337 6.32571C0.215542 6.04799 0.322588 5.78388 0.499288 5.56838C0.675989 5.35287 0.914002 5.19615 1.18179 5.11898L17.6766 0.117413H17.6895C17.9244 0.0514316 18.1726 0.0491185 18.4086 0.110711C18.6446 0.172304 18.86 0.295584 19.0327 0.467905C19.2054 0.640225 19.3291 0.855381 19.3912 1.0913C19.4532 1.32721 19.4514 1.5754 19.3859 1.81038Z"
            fill="currentColor"
        />
    </svg>
</template>
