<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 1110 767"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.4"
            d="M1066.96 766.692C1100.86 684.715 1114.57 595.797 1106.95 507.417C1099.33 419.036 1070.6 333.777 1023.18 258.812C975.749 183.846 911.014 121.365 834.414 76.624C757.815 31.8832 671.592 6.19089 582.997 1.70794C494.402 -2.775 406.026 14.0825 325.301 50.8627C244.577 87.6428 173.865 143.27 119.114 213.066C64.362 282.861 27.1713 364.784 10.6697 451.944C-5.83194 539.104 -1.16199 628.953 24.2868 713.932"
            stroke="url(#paint0_linear_6032_696)"
            stroke-dasharray="12 12"
        />
        <defs>
            <linearGradient
                id="paint0_linear_6032_696"
                x1="555"
                y1="1"
                x2="555"
                y2="766.692"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#666666" />
                <stop offset="0.198805" stop-color="#F8F8F8" />
            </linearGradient>
        </defs>
    </svg>
</template>
