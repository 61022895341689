<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 7 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.00001 6.9641V13.5004C4.00001 13.633 3.94733 13.7601 3.85356 13.8539C3.75979 13.9477 3.63261 14.0004 3.50001 14.0004C3.3674 14.0004 3.24022 13.9477 3.14645 13.8539C3.05268 13.7601 3.00001 13.633 3.00001 13.5004V6.9641C2.12421 6.83769 1.3288 6.38425 0.773858 5.69502C0.218913 5.0058 -0.0543757 4.13195 0.008992 3.24936C0.0723597 2.36676 0.46768 1.54092 1.11539 0.938042C1.76311 0.335165 2.61514 0 3.50001 0C4.38488 0 5.2369 0.335165 5.88462 0.938042C6.53233 1.54092 6.92765 2.36676 6.99102 3.24936C7.05439 4.13195 6.7811 5.0058 6.22615 5.69502C5.67121 6.38425 4.8758 6.83769 4.00001 6.9641Z"
            fill="currentColor"
        />
    </svg>
</template>
