<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.4623 3.48755C18.2173 1.23969 15.2315 0.00114047 12.0502 0C5.4948 0 0.159689 5.33454 0.157408 11.8917C0.156267 13.9878 0.704263 16.0338 1.74494 17.8369L0.0576172 24L6.36212 22.3463C8.09904 23.2941 10.0549 23.793 12.0451 23.7936H12.0502C18.6044 23.7936 23.9402 18.4585 23.9424 11.9013C23.9436 8.72347 22.7079 5.73599 20.4623 3.48812V3.48755ZM12.0502 21.7852H12.0462C10.2728 21.7847 8.53301 21.3079 7.01507 20.4075L6.65409 20.1932L2.91278 21.1745L3.91126 17.5267L3.67632 17.1526C2.68696 15.5788 2.16406 13.7597 2.1652 11.8923C2.16748 6.44251 6.60162 2.00836 12.0542 2.00836C14.6944 2.0095 17.1761 3.03878 19.0424 4.90743C20.9088 6.77553 21.9358 9.25948 21.9347 11.9002C21.9323 17.3505 17.4982 21.7847 12.0502 21.7847V21.7852ZM17.472 14.3824C17.1749 14.2336 15.714 13.5151 15.4414 13.4159C15.1688 13.3167 14.971 13.2671 14.7731 13.5647C14.5752 13.8624 14.0056 14.5319 13.8322 14.7297C13.6588 14.9281 13.4855 14.9527 13.1884 14.8038C12.8913 14.655 11.9339 14.3414 10.7986 13.3292C9.91528 12.5411 9.31876 11.5683 9.14546 11.2706C8.9721 10.973 9.12722 10.8122 9.27543 10.6645C9.40886 10.5311 9.57253 10.3172 9.72135 10.1439C9.87023 9.97052 9.91926 9.84625 10.0184 9.64834C10.1177 9.4499 10.0681 9.2766 9.99397 9.12772C9.91979 8.9789 9.32566 7.51622 9.07759 6.92149C8.83635 6.34214 8.5912 6.42084 8.40928 6.41114C8.23591 6.40259 8.03807 6.40088 7.83962 6.40088C7.64118 6.40088 7.31953 6.47501 7.04698 6.77267C6.77443 7.07031 6.00687 7.78937 6.00687 9.25145C6.00687 10.7135 7.07153 12.1272 7.22034 12.3256C7.36916 12.5241 9.3159 15.5252 12.2965 16.8128C13.0054 17.119 13.559 17.302 13.9907 17.4389C14.7024 17.6653 15.3501 17.6334 15.8622 17.5569C16.4331 17.4714 17.6203 16.8379 17.8677 16.1439C18.1152 15.4499 18.1152 14.8546 18.0411 14.7308C17.967 14.6071 17.7685 14.5324 17.4714 14.3836L17.472 14.3824Z"
            fill="currentColor"
        />
    </svg>
</template>
