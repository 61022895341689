<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 360 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="w-full md:w-4/5"
    >
        <path
            opacity="0.4"
            d="M460.007 422C478.546 376.927 486.047 328.037 481.88 279.443C477.713 230.849 461.999 183.971 436.06 142.752C410.121 101.534 374.715 67.1801 332.82 42.5803C290.926 17.9805 243.768 3.8541 195.312 1.38925C146.857 -1.07561 98.5212 8.19315 54.3706 28.416C10.22 48.6388 -28.4545 79.2243 -58.3999 117.6C-88.3453 155.976 -108.686 201.019 -117.711 248.942C-126.737 296.866 -124.182 346.267 -110.264 392.991"
            stroke="url(#paint0_linear_6453_703)"
            stroke-dasharray="12 12"
        />
        <defs>
            <linearGradient
                id="paint0_linear_6453_703"
                x1="180"
                y1="1"
                x2="180"
                y2="422"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#666666" />
                <stop offset="0.198805" stop-color="#F8F8F8" />
            </linearGradient>
        </defs>
    </svg>
</template>
